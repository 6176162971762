<template>
    <div class="m-container">
        <div class="m-page">
            <h1>招商<span class="black">资讯</span></h1>
            <h2>INVESTMENT INFORMATION</h2>
        </div>

        <div class="m-story-content">
            <div class="m-story-title" v-for="(item,index) in zszxData" @click="toDetail(item.newsId)" :key="index">
                <div class="newsImg">
                    <img class="newsImg" :src="item.titleImage">
                </div>
                <div class="newsTitle">
                    <p>{{item.newsTitle}}</p>
                    <p class="time">{{item.infoStartTime}}</p>
                </div>
            </div>
        </div>
		<p class="m-look-more" @click="moreDetail">查看更多</p>
    </div>
</template>

<script>

    export default {
        name: "zszx",
        data() {
            return {
              

            }
        },
        mounted() {
           
        },
		props:{
			zszxData:{
				type:Array,
				default:null
			}
		},
        methods: {
			
            // 详情跳转
            toDetail(id) {
               this.$router.push({path: '/mobile/information/detail', query: {id: id}})
            },
            moreDetail() {
                this.$router.push({path: '/mobile/information?typeId=zszx', query: {typeId: 'zszx'}})
            }
        }
    }
</script>

<style scoped>
    .m-container {
        background-color: #f5f5f5;
    }

    .m-all {
        font-size: 2.1667rem;
    }

    .m-val {
        font-size: 1.9rem;
    }

    .black {
        color: #000000;
    }

    .m-story-tab {
        display: flex;
        display: -webkit-flex;
        align-items: center;
        justify-content: space-between;
    }

    .m-story-tab li {
        padding: 0 1rem 1rem;
        text-align: center;
        font-size: 1rem;
        color: #666666;
        white-space: nowrap;
    }

    .m-story-tab li.active {
        color: #005bac;
        font-weight: bold;
    }

    .newsImg {
        width: 30vw;
        height: 19vw;
        border-radius: 2vw;
    }

    .newsTitle {
        font-size: 3.7vw;
        color: #000000;
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: pre-line;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        width: 56vw;
        line-height: 6vw;
        margin-left: 3vw;
        margin-top: -2vw;
    }
.newsTitle p{
		text-overflow: -o-ellipsis-lastline;
		  overflow: hidden;
		  text-overflow: ellipsis;
		  display: -webkit-box;
		  -webkit-line-clamp: 2;
		  line-clamp: 2;
		  -webkit-box-orient: vertical;
	}
    .m-story-content {
        display: flex;
        flex-wrap: wrap;
        margin: 0 4vw;
        justify-content: space-between;
        padding-bottom: 4vw;
    }

    .m-story-title {
        margin: 2.5vw 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .time {
        font-size: 3vw;
        color: #999999;
        margin-top: 2vw;
    }
	.m-look-more {
	    font-size: 1rem;
	    color: #fff;
	    width: 6rem;
	    height: 2.33333333333rem;
	    background: #005bac;
	    border-radius: 2.3rem;
	    line-height: 2.3rem;
	    margin: 0 auto;
	    text-align: center;
	    padding-bottom: 1.4rem;
	    margin-top: 3vw;
	    margin-bottom: 8vw;
	}
</style>
