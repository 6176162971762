<template>
    <div class="m-container m-news-container">
        <div class="m-page">
            <h1>侨务<span class="black">资讯</span></h1>
            <h2>OVERSEAS CHINESE AFFAIRS INFORMATION</h2>
        </div>
        <ul class="m-news">
            <li v-for="(item,index) in qwzsData" @click="toDetail(item.newsId)" :key="index">
                <div class="news-img">
                    <img :src="item.titleImage" alt="">
                </div>
                <p>{{item.newsTitle}}</p><!-- {{item.newsTitle}} -->
            </li>
        </ul>
        <p class="m-look-more" @click="moreDetail">查看更多</p>
    </div>

</template>
<script>
    export default {
        name: "page-2",
        data() {
            return {
          
            }
        },
        created() {
           
        },
        props:{
			qwzsData:{
				type:Array,
				default:null
			}
		},
        methods: {
        
            // 详情
            toDetail(id) {
                 this.$router.push({path: '/mobile/information/detail', query: {id: id}})
            },
            moreDetail() {
                this.$router.push({path: '/mobile/information?typeId=qwzx', query: {typeId: 'qwzx'}})
            }
        }
    }
</script>

<style scoped>
    .m-news-container {
        padding: .8rem 0 1.8rem;
    }

    .m-news {
        display: flex;
        display: -webkit-flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 0 1rem;
        flex-wrap: wrap;
    }

    .m-news li {
        width: 48%;
        margin-bottom: 1.5rem;
    }

    .news-img {
        height: 7.5rem;
        border-radius: 2vw;
    }

    .news-img img {
        width: 100%;
        height: 100%;
        border-radius: 1.6vw;
        /* object-fit: cover; */
    }

    .m-news p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        text-align: left;
        -webkit-box-orient: vertical;
        font-size: 1rem;
        color: #000;
        margin-top: .6rem;
        line-height: 1.5;
    }

    .black {
        color: #000000;
    }

    .m-story-tab {
        margin: 0 17vw;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        justify-content: space-between;
    }

    .m-story-tab li {
        padding: 0 1rem 1rem;
        text-align: center;
        font-size: 1rem;
        color: #666666;
        white-space: nowrap;
    }

    .m-story-tab li.active {
        color: #005bac;
        font-weight: bold;
    }

    .m-look-more {
        font-size: 1rem;
        color: #fff;
        width: 6rem;
        height: 2.33333333333rem;
        background: #005bac;
        border-radius: 2.3rem;
        line-height: 2.3rem;
        margin: 0 auto;
        text-align: center;
        padding-bottom: 1.4rem;
        margin-top: 3vw;
        margin-bottom: 8vw;
    }

    .m-story-tab li.activeM {
        color: #005bac;
    }
</style>
