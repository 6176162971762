<template>
    <div class="m-container m-news-container">
        <div class="m-page">
            <h1>会员<span class="black">企业</span></h1>
            <h2>MEMBER ENTERPRISES</h2>
        </div>
       <div class="swiper-container " id="swiperhyqy">
       	<div class="swiper-wrapper ">
       		<div class="swiper-slide" @click="goDetail(item.qyWebUrl)"
       			v-for="item,index in hyqyData" :key = "index">
				<div class="swiperImgBox">
					<img :src="item.qyImage" >
				</div>
				<p>{{item.qyName}}</p>
       		</div>
       	</div>
       </div>
        <p class="m-look-more" @click="moreDetail">查看更多</p>
    </div>

</template>
<script>
	import Swiper from 'swiper'
    export default {
        name: "hyqy",
        data() {
            return {
                 hyqyData:[]
            }
        },
        created() {
           this.gethyqyData()
        },
        methods: {
             gethyqyData(){
				 this.$http.MhyzqHyqyTopList().then(res => {
					 if(res.code ==200){
						 this.hyqyData = res.data
						 // console.log(this.hyqyData)
						 setTimeout(() => {
						     this.swiper();
						 }, 10);
					 }
				 })
			 },
           
            moreDetail() {
               this.$router.push({path:'/mobile/hyqy/list',query:{typeId:'hyqy'}})
            },
			goDetail(url){
				if((url != null && url != '' && typeof (url) != 'undefined')){
					window.location.href = url
				}
				
			},
			swiper(){
				new Swiper('#swiperhyqy', {
				    // scrollbar: '.swiper-scrollbar',
				    freeMode: true,
				    scrollbarHide: true,
				    slidesPerView: 'auto',
				    centeredSlides: false,
				    // spaceBetween: 15	,
				    grabCursor: true,
				    slidesOffsetBefore: 20,
				    slidesOffsetAfter: 30,
				});
			}
        }
    }
</script>

<style scoped>
    .m-news-container {
        padding: .8rem 0 0;
    }
.swiper-container {
	    width: 100%;
	    margin: 0 auto;
	}
    .swiper-wrapper{
		display: flex;
	}
   .swiper-slide{
	 width: 9.333333rem;
	 height: 6.066666rem;
	 margin-right: 1rem;
	
   }
   .swiper-slide .swiperImgBox{
	   width: 9.333333rem;
	   height: 4.4rem;
	   box-sizing: border-box;
	   padding: 0.333333rem;
	   border: 0.066666rem solid #DCDCDC;
	   margin-bottom: 0.333333rem;
   }
   .swiper-slide img{
	width:100%;
	height:100%;
   }
   .swiper-slide p{
	   text-align: center;
	   font-size: 1rem;
	   color: #333333;
   }
    .m-look-more {
        font-size: 1rem;
        color: #fff;
        width: 6rem;
        height: 2.33333333333rem;
        background: #005bac;
        border-radius: 2.3rem;
        line-height: 2.3rem;
        margin: 0 auto;
        text-align: center;
        padding-bottom: 1.4rem;
        margin-top: 3vw;
        margin-bottom: 8vw;
    }

</style>
