<template>
    <div class="m-container m-news-container">
        <div class="m-page">
            <h1>各地<span class="black">分会</span></h1>
            <h2>LOCAL CHAMBERS OF COMMERCE</h2>
        </div>
      <ul class="index-flfgBox">
      	<li v-for="(item,index) in gdfhData" :key="index" @click="goDetail(item.webUrl)">
			<div class="dian"></div>
			<p>{{item.shfhName}}</p>
		</li>
      </ul>
        <p class="m-look-more" @click="moreDetail">查看更多</p>
    </div>

</template>
<script>
    export default {
        name: "gdfh",
        data() {
            return {
                gdfhList:[],
				
            }
        },
        created() {
          this.getgdfhData()
        },
        methods: {
			getgdfhData(){
				this.$http.shfhList({page: 1}).then(res => {
				    if (res.code == 200) {
				       this.gdfhList = res.data.records;
					   // console.log(this.gdfhList)
				    }
				})
			},
           goDetail(url) {
              window.location.href = url
           },
            moreDetail() {
                this.$router.push({
                	path:'/mobile/gdfh/list'
                })
            },
        },
		computed:{
			gdfhData:function(){
				return  this.gdfhList.slice(0,5)
			}
		}
    }
</script>

<style scoped>
    .m-news-container {
      
    }
	ul.index-flfgBox{
		list-style: none;
		padding: 0 1rem;
	}
	ul.index-flfgBox li{
		display: flex;
		align-items: center;
        margin-bottom: 1.333333rem;
	}
	ul.index-flfgBox li>.dian{
		width: 0.6rem;
		height: 0.6rem;
		border-radius: 0.6rem;
		background-color: #005bac;
		margin-right: 0.866666rem;
	}
	ul.index-flfgBox li>p{
		font-size: 1rem;
		color: #000000;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		word-break: break-all;
	}
    .m-look-more {
        font-size: 1rem;
        color: #fff;
        width: 6rem;
        height: 2.33333333333rem;
        background: #005bac;
        border-radius: 2.3rem;
        line-height: 2.3rem;
        margin: 0 auto;
        text-align: center;
        padding-bottom: 1.4rem;
        margin-top: 3vw;
        margin-bottom: 8vw;
    }

</style>
