<template>
    <div class="m-container m-news-container">
        <div class="m-page">
            <h1>会员<span class="black">专区</span></h1>
            <h2>MEMBER ZONE</h2>
        </div>
		<!-- tap栏切换 -->
		<div class="swiper-container swiperContainerTap" id="swipertap">
			<div class="swiper-wrapper swiperWrapperTap">
				<div class="swiper-slide swiperSlideTap" 
					v-for="item,index in taplist" :key = "index">
					<div class="type-list-SortName" v-if="item.typeSortName != ''">【{{item.typeSortName}}】</div>
					<div class="type-list" v-for="(list,i) in item.list" :key="i" 
					:class="list.typeId == memberTypeIndex?'active':''"
					@click="changememberType(list.typeId)">
					{{list.typeName}}
					</div>
				</div>
			</div>
		</div>
		<div class="swiper-container swiperContainerList" id="swiperList">
			<div class="swiper-wrapper swiperWrapperList">
				<div class="swiper-slide swiperSlideList"
					v-for="item,index in hyzqList" :key = "index" @click="goDetailhyzq(item.fcId)">
					<img :src="item.fcImage" >
					<div class="swiperSlideBox">
						<h3>{{item.fcName}}</h3>
						<p>{{item.remark}}</p>
					</div>
				</div>
			</div>
		</div>

        <p class="m-look-more" @click="moreDetail">查看更多</p>
    </div>

</template>

<script>
	import $ from 'jquery'
	import Swiper from 'swiper'
    export default {
        name: "page-8",
        data() {
            return {
                dataRes: [],
				selectactive:null,
				hyzqList:[],
				swiperList:null,
				memberTypeIndex:'',
				taplist:[]
            }
        },
        created() {
            this.getData();
			this.gettapList()
        },
        methods: {
			// 获取会员专区的数据
            getData() {
                this.$http.mobileIndexhyzq().then(res => {
                    if (res.code == 200) {
						// 会员专区数据
                        this.dataRes = res.data
						// 默认获取会员专区第一个值的id 用来激活第一个tap栏
						this.selectactive = this.dataRes[0].typeId
						// 获取第一个tap栏的第一个数据
						this.hyzqList = this.dataRes[0].hyList
						// 异步才能用
						setTimeout(() => {
						   
							this.swiperInit2();
						}, 10);
                    }
                })
            },
			// 跳转至详情
            toDetail(item) {
                this.$router.push({path: '/mobile/member/detail', query: {id: item.fcId}})
            },
			// 跳转到更多
            moreDetail() {
                this.$router.push({
                	path:'/mobile/member',
					query:{
						typeId:'hyfc'
					}
                })
            },
			
			swiperInit() {
			    new Swiper('#swipertap', {
			        // scrollbar: '.swiper-scrollbar',
			        freeMode: true,
			        scrollbarHide: true,
			        slidesPerView: 'auto',
			        centeredSlides: false,
			        // spaceBetween: 15	,
			        grabCursor: true,
			        slidesOffsetBefore: 20,
			        slidesOffsetAfter: 30,
			    });
			},
			swiperInit2() {
			    var swiperList1 = new Swiper('#swiperList', {
			        // scrollbar: '.swiper-scrollbar',
			        freeMode: true,
			        scrollbarHide: true,
			        slidesPerView: 'auto',
			        centeredSlides: false,
			        // spaceBetween: 15	,
					// autoWidth:true,
			        grabCursor: true,
			        slidesOffsetBefore: 20,
			        slidesOffsetAfter: 30,
					observer:true,  // 自动更新swiper-slide宽度
			    });
				this.swiperList = swiperList1
			},
			goDetailhyzq(id){
				this.$router.push({
				    path: '/mobile/member/detail',
				    query: {
				        id: id
				    }
				})
			},
			// 获取列表信息
			gettapList(){
				this.$http.hyzqTypeList().then(res => {
				    if (res.code == 200) {
						this.taplist = res.data
						// console.log(this.taplist)
						 this.memberTypeIndex = this.taplist[0].list[0].typeId
						 // console.log(this.taplist[0].list[0].typeId)
						 setTimeout(()=>{
							  this.swiperInit();
						 },10)
				    }
				})
			},
			changememberType(id){
				this.memberTypeIndex = id
				this.dataRes.forEach((item)=>{
					if(item.typeId == id){
						this.hyzqList = item.hyList
					}
				})
				// 异步  点击后回到最左边
				setTimeout(()=>{
					$('.swiper-wrapper.swiperWrapperList').css("transform", 'translate3d(20px,0px,0px)');
				},10)
			}
        }
    }
</script>

<style scoped>
	#swiperList{
		min-height: 67.2vw;
	}
    .m-news-container {
        padding: .8rem 0 1.8rem;
        background: url(../../../assets/images/mobile/honor-bg.png) no-repeat;
        background-size: 100% 100%;
    }
	/* 顶部tap栏切换 */
	.swiper-container.swiperContainerTap {
	    width: 100%;
	    margin: 0 auto;
	}
    .swiper-wrapper.swiperWrapperTap{
		display: flex;
	}
	.swiper-wrapper.swiperWrapperTap .swiper-slide.swiperSlideTap{
		display: flex;
		flex-shrink: 0;
		margin-left: 2vw;
		width: initial;
		position: relative;
	}
	.swiper-wrapper.swiperWrapperTap .swiper-slide.swiperSlideTap::after{
		content: "";
		position: absolute;
		right: 0;
		height: 5vw;
		width: 1px;
		background: #000000;
	}
	.swiper-wrapper.swiperWrapperTap .swiper-slide.swiperSlideTap .type-list-SortName{
		font-size: 3.2vw;
		color: #333333;
		flex-shrink: 0;
		font-weight: bold;
	}
	.swiper-wrapper.swiperWrapperTap .swiper-slide.swiperSlideTap .type-list{
		display: flex;
		flex-shrink: 0;
		font-size: 3.2vw;
		height: 25px;
		color: #333333;
		margin-right: 3vw ;
		position: relative;
		width: initial;
	}
   .swiper-wrapper.swiperWrapperTap .swiper-slide.swiperSlideTap .type-list.active{
	
	  /* background-color: #005bac; */
	  position: relative;
   }
   .swiper-wrapper.swiperWrapperTap .swiper-slide.swiperSlideTap .type-list.active::after{
	   content: "";
	   position: absolute;
	   left: 0;
	   bottom: 0;
	   height: 0.125rem;
	   width: 100%;
	   background: #005bac;
   }
   /* 会员专区列表 */
   .swiper-container.swiperContainerList {

   	    /* width: 100%; */
   	    margin: 25px auto;
   	}
    .swiper-wrapper.swiperWrapperList{
   		display: flex;
   	}
   	.swiper-wrapper.swiperWrapperList .swiper-slide.swiperSlideList{
		display: flex;
		flex-direction: column;
   		background-color: #e5e5e5;
   		margin-left: 0.5rem;
   		font-size: 0.466666rem;
   		color: #333333;
   		width:9.333333rem;
   		height: 16.933333rem;
   	}
	.swiper-wrapper.swiperWrapperList .swiper-slide.swiperSlideList img{

		width: 9.333333rem;
		height: 12.666666rem;

	}
	.swiper-wrapper.swiperWrapperList .swiper-slide.swiperSlideList .swiperSlideBox{

		width: 9.333333rem;
		height: 4.266664rem;
		background-color: #f8f8f8;
	}
	.swiper-slide.swiperSlideList .swiperSlideBox{
		height: 9.333333rem;
	}
	.swiper-slide.swiperSlideList .swiperSlideBox h3{
		font-size: 1.066666rem;
		color: #333333;
		margin:  0.8rem 0 0.533333rem 0.8rem ;
	}
	.swiper-slide.swiperSlideList .swiperSlideBox p{
		margin-left: 0.8rem;
		font-size: 0.866666rem;
		color: #999999;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		word-break: break-all;
	}

   /* 更多按钮 */
    .m-look-more {
        font-size: 1rem;
        color: #fff;
        width: 6rem;
        height: 2.33333333333rem;
        background: #005bac;
        border-radius: 2.3rem;
        line-height: 2.3rem;
        margin: 0 auto;
        text-align: center;
        padding-bottom: 1.4rem;
        margin-top: 3vw;
        margin-bottom: 8vw;
    }

</style>
