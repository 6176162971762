<template>
    <div class="m-container m-news-container">
        <div class="m-page">
            <h1>法律<span class="black">法规</span></h1>
            <h2>LAWS AND REGULATIONS</h2>
        </div>
      <ul class="index-flfgBox">
      	<li v-for="item,index in flfgData" :key="index" @click="goDetail(item.newsId)">
			<div class="dian"></div>
			<p>{{item.newsTitle}}</p>
		</li>
      </ul>
        <p class="m-look-more" @click="moreDetail">查看更多</p>
    </div>

</template>
<script>
    export default {
        name: "flfg",
        data() {
            return {
                
            }
        },
		props:{
			flfgData:{
				type:Array,
				default:null
			}
		},
        created() {
          
        },
        methods: {
           goDetail(id) {
               this.$router.push({path:'/mobile/information/detail', query: {id: id}})
           },
            moreDetail() {
                this.$router.push({path:'/mobile/information?typeId=flfg', query: {typeId: 'flfg'}})
            },
        }
    }
</script>

<style scoped>
    .m-news-container {
      
    }
	ul.index-flfgBox{
		list-style: none;
		padding: 0 1rem;
	}
	ul.index-flfgBox li{
		display: flex;
		align-items: center;
        margin-bottom: 1.333333rem;
	}
	ul.index-flfgBox li>.dian{
		width: 0.6rem;
		height: 0.6rem;
		border-radius: 0.6rem;
		background-color: #005bac;
		margin-right: 0.866666rem;
		flex-shrink: 0;
	}
	ul.index-flfgBox li>p{
		font-size: 1rem;
		color: #000000;
		white-space: nowrap;
		text-overflow: ellipsis;
		overflow: hidden;
		word-break: break-all;
	}
    .m-look-more {
        font-size: 1rem;
        color: #fff;
        width: 6rem;
        height: 2.33333333333rem;
        background: #005bac;
        border-radius: 2.3rem;
        line-height: 2.3rem;
        margin: 0 auto;
        text-align: center;
        padding-bottom: 1.4rem;
        margin-top: 3vw;
        margin-bottom: 8vw;
    }

</style>
