<template>
    <div class="m-container  m-field-container">
        <div class="m-page">
            <h1>商会<span class="black">刊物</span></h1>
            <h2>CHAMBER OF COMMERCE PUBLICATIONS</h2>
        </div>
        <div class="m-field">
            <div class="swiper-container" id="swiper4">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item,i) in dataRes" :key="i">
                        <router-link class="field-img" :to="{path:'/mobile/shkw/detail',query:{id:item.shkwId}}">
                            <img :src="item.shkwImage" alt="">
                            <div class="info">
                                <p>{{item.shkwName}}</p>
                                <div class="bookTime">
                                    <span class="accordion-value">{{item.shkwQS}}</span>
                                    <span>期</span>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <p class="m-look-more" @click="moreDetail">查看更多</p>
    </div>

</template>

<script>
    import Swiper from 'swiper'

    export default {
        name: "page-9",
        data() {
            return {
                accordionList: [],
                dataRes: [],
            }
        },
        mounted() {
            this.getData();
        },
        methods: {
            getData() {
                this.$http.MshkwTopList().then(res => {
                    if (res.code == 200) {
                        this.dataRes = res.data
                        setTimeout(() => {
                            this.swiperInit();
                        }, 10);
                    }
                })
            },
            moreDetail() {
                this.$router.push({path: '/mobile/shkw/list'})
            },
            swiperInit() {
                new Swiper('#swiper4', {
                    //scrollbar: '.swiper-scrollbar',
                    //scrollbarHide: true,
                    freeMode: true,
                    scrollbarHide: true,
                    slidesPerView: 'auto',
                    centeredSlides: false,
                    // spaceBetween: 15	,
                    grabCursor: true,
                    slidesOffsetBefore: 20,
                    slidesOffsetAfter: 30,
                });
            }
        }
    }
</script>

<style scoped>

    .swiper-container {
        width: 100%;
        /* height: 10.7333333333rem; */
        margin: 0 auto;
    }

    .swiper-slide {
        text-align: center;
        /* font-size: 18px; */
        background: #fff;
        width: 37vw;
        /* height: 67vw; */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        margin-right: 4vw;
    }

    .m-field {
        margin-bottom: 6vw;
    }

    .field-img {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;
    }

    .info {
        padding: 2vw 3vw 3vw 2vw;
        background-color: #f8f8f8;
    }

    .bookTime {
        text-align: right;
        font-size: 4vw;
    }

    /* .field-img::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, .3);
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 8;
    } */

    .field-img img {
        width: 37vw;
        height: 49vw;
        /* object-fit: cover; */
    }

    .field-img p {
        top: 1.5rem;
        /* position: absolute; */
        z-index: 9;
        color: #fff;
        font-size: 4vw;
        color: #333333;
        text-align: left;
        /* font-weight: bold; */
        /* padding: 0 .7rem; */
        padding-bottom: 2vw;
    }

    /* .field-img p::after {
      content: "";
      position: absolute;
      width: 2.1rem;
      height: 0.1rem;
      bottom: 0;
      left: .7rem;
      background: #fff;
    } */
    .field-img span {
        /* font-size: 3vw; */
        color: #333333;
        text-align: left;
        /* display: block; */
        /*padding: 0 .7rem;*/
        /* font-weight: bold; */
        /*padding-top: .67rem;*/
        /* position: absolute; */
        bottom: 1rem;
        left: .7rem;
        z-index: 9;
    }

    .accordion-value {
        font-size: 6vw;
    }

    .black {
        color: #000000;
    }

    .m-look-more {
        font-size: 1rem;
        color: #fff;
        width: 6rem;
        height: 2.33333333333rem;
        background: #005bac;
        border-radius: 2.3rem;
        line-height: 2.3rem;
        margin: 0 auto;
        text-align: center;
        padding-bottom: 1.4rem;
        margin-top: 3vw;
        margin-bottom: 8vw;
    }
</style>
