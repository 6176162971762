<template>
  <div class="banner-swiper">
    <div class="swiper-container"  id="swiper1">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <img src="@/assets/images/mobile/banner.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import  Swiper from 'swiper'
  export default {
    name: "page-1",
    mounted() {
      this.$nextTick(()=>{
         new Swiper('#swiper1', {
          //autoplay : 6000,//可选选项，自动滑动
          loop : true,//可选选项，开启循环
          autoplayDisableOnInteraction : false,
        })
      })
    },
  }
</script>

<style scoped>
  .banner-swiper{
    width: 100vw;
    height: 37vw;
    /* overflow: hidden; */
  }
  .swiper-container{
    width: 100%;
    height: 100%;
    margin: 13vw 0 0;
  }
  .swiper-wrapper{
    width: 100%;
  }
  .swiper-slide {
    width: 100%;
    display: block;
    box-sizing: border-box;
    margin-right: 0 !important;
  }
  .swiper-slide img {
    width: 100%;
  }

</style>
