<template>
    <div class="m-index">
        <v-header></v-header>
        <page-1></page-1>
        <page-2 :qwzsData = "qwzxList"></page-2>
        <page-3 :jrszData = "jrszList"></page-3>
        <page-4 :bhxwData = "bhxwList"></page-4>
        <page-5 :hydtData = "hydtList"></page-5>
        <page-6 :qqfcData = "qqfcList"></page-6>
        <page-7 :dfshData = "dfshList"></page-7>
        <page-8></page-8>
		<pagehyqy></pagehyqy>
		<pageflfg :flfgData = "flfgList"></pageflfg>
		<pageztbd :ztbdData="ztbdList"></pageztbd>
		<pagegdfh></pagegdfh>
		<pagezszx :zszxData="zszxList"></pagezszx>
        <page-9></page-9>
        <page-10 :xxgkData ="xxgkList"></page-10>
        <page-11 :tzggData  ="tzggList"></page-11>
        <v-footer></v-footer>
    </div>
</template>

<script>
    import vHeader from "@/components/mobile/vHeader.vue";
    import vFooter from "@/components/mobile/vFooter.vue";
    import page1 from './base/index/page-1.vue'
    import page2 from './base/index/page-2.vue'
    import page3 from './base/index/page-3.vue'
    import page4 from './base/index/page-4.vue'
    import page5 from './base/index/page-5.vue'
    import page6 from './base/index/page-6.vue'
    import page7 from './base/index/page-7.vue'
    import page8 from './base/index/page-8.vue'
	import pagehyqy from "./base/index/page-hyqy.vue"
	import pageflfg from "./base/index/page-flfg.vue"
	import pageztbd from "./base/index/page-ztbd.vue"
	import pagegdfh from "./base/index/page-gdfh.vue"
	import pagezszx from "./base/index/page-zszx.vue"
    import page9 from './base/index/page-9.vue'
    import page10 from './base/index/page-10.vue'
    import page11 from './base/index/page-11.vue'
    // import {addReadLogApi} from '@/api/readlog.js'
    // import {check} from '../util/checkPCorMobile.js'
    export default {
        name: "index",
        components: {
            vHeader,
            vFooter,
            page1,
            page2,
            page3,
            page4,
            page5,
            page6,
            page7,
            page8,
			pagehyqy,
			pageflfg,
			pageztbd,
			pagegdfh,
			pagezszx,
            page9,
            page10,
            page11,
        },
        created() {
            // this.addReadLog()
			this.getIndexHalf()
			this.getIndexAll()
			if (!/Android|webOS| iPhone localStorage| iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i
			    .test(navigator.userAgent)) {
						// console.log(123)
			        this.$router.push({
			            path: "/index"
			        });
			}else{
				this.getMcountFn()
			}
        },
		data(){
			return{
				qwzxList:[], //侨务资讯数据
				jrszList:[], //今日时政数据
				bhxwList:[], //本地新闻数据
				hydtList:[], //会员动态数据
				qqfcList:[], //侨企风采数据
				dfshList:[], //地方商会数据 
				flfgList:[], //法律法规数据
				ztbdList:[], //专题报道数据
				zszxList:[], //招商资讯数据
				xxgkList:[], //信息公开数据
				tzggList:[], //通知公告数据
			}
		},
        watch: {
            $route: {
                immediate: true, // 一旦监听到路由的变化立即执行
                handler(to) {
                    if (to.name === 'mIndex') {
                        this.wxShareInfo();
                    }
                },
            },
        },
        methods: {
			// 手机端获取浏览量
			getMcountFn(){
				this.$http.McountNum().then(res => {
					if (res.code == 200) {
					  // console.log(res)
					}
				})
			},
            //微信分享
            wxShareInfo() {
                const {wxSdkUtil} = require("@/util/wechat/wxSdkUtil")
                let obj = {};
                obj.content = '';
                obj.title = '中国侨商联合会';
                obj.img = 'http://' + window.location.host + '/upload/default/newstitle.jpg';
                wxSdkUtil.setShareInfo(obj.img, obj.title, obj.content, null)
            },
			// 获取首页 /侨务资讯/今日时政/本地新闻/会员动态/侨企风采/地方商会
			getIndexHalf(){
				this.$http.mobileIndexHalf().then(res =>{
					if(res.code == 200){
						// console.log(res)
						this.qwzxList = res.data.qwzxList;
						this.jrszList = res.data.jrszList;
						this.bhxwList = res.data.bhxwList;
						this.hydtList = res.data.hydtList;
						this.qqfcList = res.data.qqfcList;
						this.dfshList = res.data.dfshList;
					}
				})
			},
			// 获取首页 /  法律法规 /专题报道 /招商资讯 /信息公开 / 通知公告
			getIndexAll(){
				this.$http.mobileIndexAll().then(res =>{
					if(res.code == 200){
						// console.log(res)
						this.flfgList = res.data.flfgList;
						this.ztbdList = res.data.ztbdList;
						this.zszxList = res.data.zszxList;
						this.xxgkList = res.data.xxgkList;
						this.tzggList = res.data.tzggList;
					
					}
				})
			}
        }
    }
</script>

<style scoped>

</style>
